import { Tag } from "Domain/Model/Tag/Tag";

export const FIND_TAGS_SUCCESS = 'FIND_TAGS_SUCCESS';

interface IFindTagsSuccess {
    tags: Record<string, Tag>,
    request: {},
    response: {},
}

export const findTagsSuccess = ({ tags, request, response }: IFindTagsSuccess) => ({
    type: FIND_TAGS_SUCCESS,
    payload: { tags },
    meta: {
        request,
        response,
    }
});
