import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { SpotList }                                     from "Domain/Model/SpotList/SpotList";

export const getSpotLists = (state: IReduxStore, filter: null|IFilterProps<SpotList> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<SpotList>;
    }

    return filterObjectStore(state.spotList.spotList.entries, filter);
};
