import React from 'react';
import Style from "./PublicApp.style";
import Footer from "Component/Application/Footer/Footer";
import Content from "Component/Application/Content/Content";
import { isGlobalStateEqual } from "Redux/Query";
import { connect, MapDispatchToPropsNonObject, MapStateToProps } from "react-redux";
import { IReduxStore } from "Redux/Store/IReduxStore";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

interface IDispatchProps {

}

interface IStateProps {

}

type TProps    = IDispatchProps & IStateProps & { children: React.ReactElement };


class PublicApp extends React.Component<TProps, {}> {

    render () {
        return (
            <Style { ...this.props }>
                <Content>
                    { this.props.children }
                </Content>
                <Footer  />
            </Style>

        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, {}, IReduxStore> = (state) => {
    return {

    };
}

const mapDispatchToProps: MapDispatchToPropsNonObject<IDispatchProps, RouteComponentProps> = (dispatch) => {
    return bindActionCreators({

    }, dispatch);
};

const options = {
    areStatesEqual: (nextStore: IReduxStore, prevStore: IReduxStore) => {
        return (
            isGlobalStateEqual(nextStore, prevStore)
        );
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps,  undefined, options)(PublicApp));