import {
    APPLICATION_BOOTSTRAPPED,
    getSettingsRequest,
    findLanguagesRequest,
} from 'Redux/Action';
import { Epic, ofType }                                 from 'redux-observable';
import { mergeMap }                                     from 'rxjs/operators';
import { from }                                         from 'rxjs';

export const globalSettingsEpic: Epic = (action$) => {

    return action$.pipe(
        ofType(APPLICATION_BOOTSTRAPPED),
        mergeMap((action) => {
            return from([
                findLanguagesRequest(),
                getSettingsRequest()
            ]);
        })
    );

};