import {
    CREATE_SPOT_REQUEST,
    CREATE_SPOT_SUCCESS,
    CREATE_SPOT_FAILURE,

    DELETE_SPOT_REQUEST,
    DELETE_SPOT_SUCCESS,
    DELETE_SPOT_FAILURE,

    UPDATE_SPOT_REQUEST,
    UPDATE_SPOT_SUCCESS,
    UPDATE_SPOT_FAILURE,

    FIND_SPOT_REQUEST,
    FIND_SPOT_SUCCESS,
    FIND_SPOT_FAILURE,

    FIND_SPOTS_REQUEST,
    FIND_SPOTS_SUCCESS,
    FIND_SPOTS_FAILURE,

    SEARCH_SPOTS_REQUEST,
    SEARCH_SPOTS_SUCCESS,
    SEARCH_SPOTS_FAILURE,


    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer }                       from "redux";
import { SpotState, TSpotState } from "Redux/Store/Spot/SpotState";

const initialState = SpotState.getInitialState();

export const spotReducer: Reducer<TSpotState> = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case CREATE_SPOT_REQUEST:
    case DELETE_SPOT_REQUEST:
    case UPDATE_SPOT_REQUEST:
    case FIND_SPOT_REQUEST:
    case FIND_SPOTS_REQUEST:
    case SEARCH_SPOTS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_SPOT_SUCCESS:
    case UPDATE_SPOT_SUCCESS:
    case FIND_SPOT_SUCCESS:
        const entry = getSingleObject(action.payload);

        return {
            ...state,
            lastInteractiveId: entry.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            spot: {
                ...state.spot,
                entries: addSingleObjectToReducer(state.spot.entries, entry)
            },
        };

    case FIND_SPOTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            spot: {
                entries: action.payload.spots,
                maxCount: (action.meta) ? action.meta.response.count : state.spot.maxCount,
            },
            updatedAt: Date.now(),
        };

    case SEARCH_SPOTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            searchEntries: {
                ...state.searchEntries,
                ...action.payload.spots,
            },
            updatedAt: Date.now(),
        };

    case DELETE_SPOT_SUCCESS: {
        const entries = { ...state.spot.entries };
        delete entries[action.payload.id];
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            spot: {
                ...state.spot,
                entries: entries,
            },
        };
    }

    case CREATE_SPOT_FAILURE:
    case DELETE_SPOT_FAILURE:
    case UPDATE_SPOT_FAILURE:
    case FIND_SPOT_FAILURE:
    case FIND_SPOTS_FAILURE:
    case SEARCH_SPOTS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };


    default:
        return state;
    }
};
