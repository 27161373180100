import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { SpotList }           from "Domain/Model/SpotList/SpotList";

interface ISpotListState {
    spotList: {
        entries: Record<string, SpotList>
        maxCount: number;
    },
    searchEntries: Record<string, Record<string, SpotList>>,
    lastInteractiveId: null|string|number,
}

export type TSpotListState = ISpotListState & BaseStoreNamespace.TDefaultState;


export const SpotListState = {
    getInitialState: (): TSpotListState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        spotList: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}