import { Spot } from "Domain/Model/Spot/Spot";

export const SEARCH_SPOTS_SUCCESS = 'SEARCH_SPOTS_SUCCESS';

interface IFindSpotsSuccess {
    spots: Record<string, Spot>,
    request: {},
    response: {},
}

export const searchSpotsSuccess = ({ spots, request, response }: IFindSpotsSuccess) => ({
    type: SEARCH_SPOTS_SUCCESS,
    payload: { spots },
    meta: {
        request,
        response,
    }
});
