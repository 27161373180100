import { IReduxStore } from 'Redux/Store/IReduxStore';

export const isGlobalStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.global.updatedAt === nextStore.global.updatedAt;
export const isSpotListStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.spotList.updatedAt === nextStore.spotList.updatedAt;
export const isSpotStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.spot.updatedAt === nextStore.spot.updatedAt;
export const isTagStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.tag.updatedAt === nextStore.tag.updatedAt;

