import React from 'react';
import ContentStyle from './Content.style';

export default class Content extends React.Component {

    render() {
        return (
            <ContentStyle>
                { this.props.children }
            </ContentStyle>
        );
    }

};