import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { Spot }               from "Domain/Model/Spot/Spot";

interface ISpotState {
    spot: {
        entries: Record<string, Spot>
        maxCount: number;
    },
    searchEntries: Record<string, Spot>,
    lastInteractiveId: null|string|number,
}

export type TSpotState = ISpotState & BaseStoreNamespace.TDefaultState;


export const SpotState = {
    getInitialState: (): TSpotState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        spot: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}
