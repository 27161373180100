export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

interface IGetSettingsSuccess {
    options: Record<string, unknown>,
}

export const getSettingsSuccess = (payload: IGetSettingsSuccess) => ({
    type: GET_SETTINGS_SUCCESS,
    payload,
});
