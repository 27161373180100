import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Language }   from 'Domain/Model/Language/Language';

const config = {
    model: Language,
    baseRoute: '/language',
    singular: 'language',
    plural: 'languages',
}

class LanguageRepository extends BaseRepository<Language, typeof config.singular, typeof config.plural> {

}

export default new LanguageRepository(config);
