import {
    DELETE_SPOT_LIST_REQUEST,
    deleteSpotListSuccess,
    deleteSpotListFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const deleteSpotListEpic: TEpic = (action$, store$, { SpotListRepository }) => {
    return action$.pipe(
        ofType(DELETE_SPOT_LIST_REQUEST),
        mergeMap(action => from(SpotListRepository.remove( action.payload.id ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showSuccessNotification(`Das SpotList wurde erfolgreich gelöscht.`),
                        deleteSpotListSuccess(action.payload.id),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteSpotListFailure(error),
                    ])
                })
            )
        ),
    );
}
