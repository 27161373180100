import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { SpotList }   from 'Domain/Model/SpotList/SpotList';

const config = {
    model: SpotList,
    baseRoute: '/spot-list',
    singular: 'spotList',
    plural: 'spotLists',
}

class SpotListRepository extends BaseRepository<SpotList, typeof config.singular, typeof config.plural> {

}

export default new SpotListRepository(config);
