import { FIND_SPOTS_REQUEST, findSpotsSuccess, findSpotsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findSpotsEpic: TEpic = (action$, store$, { SpotRepository }) => {
    return action$.pipe(
        ofType( FIND_SPOTS_REQUEST ),
        mergeMap(action => from(
            SpotRepository.findAll(action.payload)
                .then(({ spots, meta }) => {
                    return findSpotsSuccess({ spots, response: meta, request: action})
                })
                .catch((error: Error) => findSpotsFailure(error)),
        )),
    );
}
