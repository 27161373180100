import { WINDOW_TYPES } from 'AppConfig/Config/WindowTypes';
import { BaseStoreNamespace }  from 'Redux/Store/BaseStore';

interface IGlobalState {
    isApplicationBootstrapped: boolean,
    isInitialized: boolean,
    activeSideMenuTab: string,
    activeCategory: null|string,
    currentWindowMode: string,
    networkError: boolean,
}

export type TGlobalState = IGlobalState & BaseStoreNamespace.TDefaultState;


export const GlobalState = {

    getInitialState: (): TGlobalState => ({
        updatedAt                : 0,
        openRequests             : [],
        failedRequests           : [],
        isApplicationBootstrapped: false,
        isInitialized            : false,
        activeSideMenuTab        : 'home',
        activeCategory           : null,
        currentWindowMode        : WINDOW_TYPES.DESKTOP,
        networkError             : false,
    })

}