import {
    DELETE_SPOT_REQUEST,
    deleteSpotSuccess,
    deleteSpotFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const deleteSpotEpic: TEpic = (action$, store$, { SpotRepository }) => {
    return action$.pipe(
        ofType(DELETE_SPOT_REQUEST),
        mergeMap(action => from(SpotRepository.remove( action.payload.id ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showSuccessNotification(`Das Spot wurde erfolgreich gelöscht.`),
                        deleteSpotSuccess(action.payload.id),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteSpotFailure(error),
                    ])
                })
            )
        ),
    );
}
