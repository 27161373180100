import { lazy } from "react";
import { IRoute } from "./IRoute";
import { PageEvents } from "Component/Page/PageEvent";

export const PrivateRoutes: {[key:string]: IRoute} = {

    dashboard: {
        path: '/admin/dashboard',
        component: lazy(() => (import('Component/Page/Private/Dashboard/Dashboard'))),
        sideboard: {
            sorting: 20,
            icon: 'appstore',
            label: 'Dashboard',
        },
        pageKey: PageEvents.DASHBOARD_PAGE,
    },
    spotLists: {
        path: '/admin/spot-list',
        component: lazy(() => (import('Component/Page/Private/SpotLists/SpotListsOverview'))),
        sideboard: {
            sorting: 100,
            icon: 'order',
            label: 'Spot Listen',
        },
        pageKey: PageEvents.SPOT_LISTS_PAGE,
    },
    tags: {
        path: '/admin/tags',
        component: lazy(() => (import('Component/Page/Private/Tags/TagsPage'))),
        sideboard: {
            sorting: 200,
            icon: 'tag',
            label: 'Tags',
        },
        pageKey: PageEvents.TAGS_PAGE,
    },

    spotListDetail: {
        path: '/admin/spot-list/:id',
        component: lazy(() => (import('Component/Page/Private/SpotLists/SpotListDetail'))),
        pageKey: PageEvents.SPOT_LIST_PAGE,
        parent: PageEvents.SPOT_LISTS_PAGE
    },
    spotDetail: {
        path: '/admin/spot-list/:spotListId/spot/:id',
        component: lazy(() => (import('Component/Page/Private/Spots/SpotDetail'))),
        pageKey: PageEvents.SPOT_LIST_PAGE,
        parent: PageEvents.SPOT_LISTS_PAGE
    },
    settings: {
        path: '/admin/settings',
        component: lazy(() => (import('Component/Page/Private/Setting/SettingsOverview'))),
        sideboard: {
            sorting: 300,
            icon: 'edit',
            label: 'Einstellungen',
        },
        pageKey: PageEvents.SETTINGS_PAGE,
        parent: PageEvents.SETTINGS_PAGE
    },


    logout: {
        path: '/logout',
        sideboard: {
            sorting: 1000,
            icon: 'logout',
            label: 'Abmelden',
        },
        pageKey: PageEvents.LOGOUT_PAGE
    },
}