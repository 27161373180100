import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Tag }   from 'Domain/Model/Tag/Tag';

const config = {
    model: Tag,
    baseRoute: '/tag',
    singular: 'tag',
    plural: 'tags',
}

class TagRepository extends BaseRepository<Tag, typeof config.singular, typeof config.plural> {

}

export default new TagRepository(config);
