import { BaseStoreNamespace } from 'Redux/Store/BaseStore';
import { Tag }                from 'Domain/Model/Tag/Tag';

interface ITagState {
    tag: {
        entries: Record<string, Tag>
        maxCount: number;
    },
    searchEntries: Record<string, Record<string, Tag>>,
    lastInteractiveId: null | string | number,
}

export type TTagState = ITagState & BaseStoreNamespace.TDefaultState;


export const TagState = {
    getInitialState: (): TTagState => ({
        openRequests     : [],
        failedRequests   : [],
        updatedAt        : 0,
        searchEntries    : {},
        tag              : {
            entries : {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
};