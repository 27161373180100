import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_TAG_REQUEST,
    FIND_TAGS_REQUEST,
    SEARCH_TAGS_REQUEST,
    CREATE_TAG_REQUEST,
    UPDATE_TAG_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isTagsLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.tag.openRequests, [
        FIND_TAG_REQUEST,
        FIND_TAGS_REQUEST,
        SEARCH_TAGS_REQUEST,
        CREATE_TAG_REQUEST,
        UPDATE_TAG_REQUEST,
    ]);