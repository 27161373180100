import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_SPOT_LIST_FAILURE,
    UPDATE_SPOT_LIST_FAILURE,
    FIND_SPOT_LIST_FAILURE,
    FIND_SPOT_LISTS_FAILURE,
    SEARCH_SPOT_LISTS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isSpotListFailed = (state: IReduxStore): boolean => ArrayContains(state.spotList.failedRequests, [
    CREATE_SPOT_LIST_FAILURE,
    UPDATE_SPOT_LIST_FAILURE,
    FIND_SPOT_LIST_FAILURE,
    FIND_SPOT_LISTS_FAILURE,
    SEARCH_SPOT_LISTS_FAILURE,
]);