import { combineEpics }   from 'redux-observable';
import NotificationEpics  from "./Notification";
import GlobalEpics        from "./Global";
import UserEpics          from "./User";
import SpotListEpics      from "./SpotList";
import SpotEpics          from "./Spot";
import ImageEpics         from "./Image";
import TagEpics           from "./Tag";
import LanguageEpics      from "./Language";


export default combineEpics(
    NotificationEpics,
    GlobalEpics,
    UserEpics,
    SpotListEpics,
    SpotEpics,
    ImageEpics,
    TagEpics,
    LanguageEpics,
);
