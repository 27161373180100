import { Spot } from "Domain/Model/Spot/Spot";

export const FIND_SPOTS_SUCCESS = 'FIND_SPOTS_SUCCESS';

interface IFindSpotsSuccess {
    spots: Record<string, Spot>,
    request: {},
    response: {},
}

export const findSpotsSuccess = ({ spots, request, response }: IFindSpotsSuccess) => ({
    type: FIND_SPOTS_SUCCESS,
    payload: { spots },
    meta: {
        request,
        response,
    }
});
