import styled from 'styled-components';
import { Layout } from 'antd';

export default styled(Layout.Footer) `
    padding: 8px 10px;
    color: white;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.6);
    text-align: right;
    position: fixed;
    height: 32px;
    width: 100%;
    bottom: 0;
    z-index: 100;
    
    .footerLink {
        float: left;
        padding: 0 20px;
        
        a, span {
            cursor: pointer;
            color: #fff !important;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .popOver {
        z-index: 100;
        text-align: left;
        color: #414141;
        font-size: 18px;
    }

    @media (max-width: 720px) {
        height: 50px;
        min-width: 320px;
        
        
        .popOver {
            .hasControls.contentContainer {
                .controls {
                    display: none;
                }
                .contentWrapper {
                    min-height: 100%;
                    height: 100%;
                }
            }
        }
    }
`;
