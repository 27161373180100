import {
    UPDATE_TAG_REQUEST,
    updateTagSuccess,
    updateTagFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const updateTagEpic: TEpic = (action$, store$, { TagRepository }) => {
    return action$.pipe(
        ofType(UPDATE_TAG_REQUEST),
        mergeMap(action => from(TagRepository.update( action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap((tag) => {
                    return from([
                        showSuccessNotification(`Die Speicherung des Tags war erfolgreich.`),
                        updateTagSuccess(tag),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateTagFailure(error)
                    ])
                })
            )
        ),
    );
}
