import { Language }     from "Domain/Model/Language/Language";

export const FIND_LANGUAGES_SUCCESS = 'FIND_LANGUAGES_SUCCESS';

interface IFindLanguagesSuccess {
    languages: Record<string, Language>,
    request: {},
    response: {},
}

export const findLanguagesSuccess = ({ languages, request, response }: IFindLanguagesSuccess) => ({
    type: FIND_LANGUAGES_SUCCESS,
    payload: { languages },
    meta: {
        request,
        response,
    }
});
