import {
    CREATE_SPOT_REQUEST,
    createSpotSuccess,
    createSpotFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const createSpotEpic: TEpic = (action$, store$, { SpotRepository }) => {
    return action$.pipe(
        ofType(CREATE_SPOT_REQUEST),
        mergeMap(action => from(SpotRepository.create( action.payload.spotData ))
            .pipe(
                mergeMap((spot) => {
                    return from([
                        showSuccessNotification(`Spot wurde erfolgreich erstellt`),
                        createSpotSuccess( spot ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createSpotFailure(error),
                    ])
                })
            )
        ),
    );
}
