import BaseRepository   from 'Domain/Repository/BaseRepository/BaseRepository';
import { Option }       from 'Domain/Model/Setting/Option';

const config = {
    model: Option,
    baseRoute: '/settings',
    singular: 'option',
    plural: 'options',
}

class SettingRepository extends BaseRepository<Option, typeof config.singular, typeof config.plural> {

    findAllOptions( filterSettings = { filter: [] }) {
        return this.makeRequest({
            path: `/public/client/settings`,
            type: 'GET',
            callback: this.initializeSettings,
        });
    }

    updateOptions(updateData = {}) {
        return this.makeRequest({
            path: `/admin/settings`,
            type: 'PATCH',
            data: updateData,
            callback: this.initializeSettings,
        });
    }

    reloadSettings() {
        return this.makeRequest({
            path: `/admin/settings/reload`,
            type: 'GET',
            callback: this.initializeSettings,
        });
    }

    initializeSettings = (responseObject: { options: Record<string, unknown>}): { options: Record<string, unknown>} => {
        return { options: responseObject.options };
    };

}

export default new SettingRepository(config);
