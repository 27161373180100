export const INVALID_LOGIN   = 'Die eingegebene E-Mail-Adresse oder Ihr Passwort ist inkorrekt.';
export const NETWORK_ERROR   = 'Es konnte keine Verbindung zum Server hergestellt werden. Überprüfen Sie Ihre Internetverbindung';
export const NO_AUTHENTIFICATION = 'Nutzername und Passwort stimmen nicht überein';
export const EXISTING_INGREDIENT = 'Die Zutat existiert bereits.';

export const ERROR_MESSAGES = {
    INVALID_LOGIN,
    NETWORK_ERROR,
    NO_AUTHENTIFICATION,
    EXISTING_INGREDIENT,
} as Record<string, string>;