import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Spot }                                     from "Domain/Model/Spot/Spot";

export const getSearchedSpots = (state: IReduxStore, filter: null|IFilterProps<Spot> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<Spot>;
    }
    console.log(state.spot.searchEntries);
    return filterObjectStore(state.spot.searchEntries, filter);
};
