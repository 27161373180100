import { combineEpics }                    from 'redux-observable';
import { getSettingsEpic }                 from './GetSettings/GetSettingsEpic';
import { globalSettingsEpic }              from './GlobalSettings/GlobalSettingsEpic';
import { reloadServerSettingsEpic }        from './ReloadServerSettings/ReloadServerSettingsEpic';
import { updateServerSettingsRequestEpic } from './UpdateServerSettings/UpdateServerSettingsRequestEpic';

export default combineEpics(
    getSettingsEpic,
    globalSettingsEpic,
    reloadServerSettingsEpic,
    updateServerSettingsRequestEpic,
);