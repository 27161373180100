import { connectRouter, routerMiddleware } from 'connected-react-router';
import Repositories from 'Domain/Repository';
import { createBrowserHistory } from 'history';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Epics from 'Redux/Epic';
import Reducers from 'Redux/Reducer';
import { IReduxStore } from "Redux/Store/IReduxStore";
import { PersistPartial } from "redux-persist/es/persistReducer";

const persistConfig = {
    key: 'livetain-backend',
    storage,
    debug: true,

    // whitelist: [],
    whitelist: ['authentication', 'user', 'persist'],
};

const epicMiddleware = createEpicMiddleware({
    dependencies: {...Repositories},
});


export let Persistor: any;

export const history = createBrowserHistory();

export const rehydrateStore = (storeToHydrate: any) => new Promise<void>((resolve, reject) => {
    Persistor = persistStore(storeToHydrate, null, () => {
        resolve();
    });
});

// IMPROVE: Do we know if we build for production?, if so, we should enable devtools only for dev/staging builds
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [epicMiddleware, routerMiddleware(history)];
if(process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}

export const Store = createStore<IReduxStore & PersistPartial, AnyAction, {}, {}>(
    persistCombineReducers<IReduxStore>(persistConfig, { ...Reducers, router: connectRouter(history) }),
    composeEnhancers(
        applyMiddleware( ...middlewares )
    ),
);

export type TEpic = Epic<any, any, any, typeof Repositories>

epicMiddleware.run(Epics);