import { FIND_LANGUAGES_REQUEST, findLanguagesSuccess, findLanguagesFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findLanguagesEpic: TEpic = (action$, store$, { LanguageRepository }) => {
    return action$.pipe(
        ofType( FIND_LANGUAGES_REQUEST ),
        mergeMap(action => from(
            LanguageRepository.findAll(action.payload)
                .then(({ languages, meta }) => {
                    return findLanguagesSuccess({ languages, response: meta, request: action})
                })
                .catch((error: Error) => findLanguagesFailure(error)),
        )),
    );
}
