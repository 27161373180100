import React from 'react';
import FooterStyle from './Footer.style';
import { version } from 'AppConfig/Config';

export default class Footer extends React.Component {

    render() {
        const year = new Date().getFullYear();

        return (
            <FooterStyle>
                &copy; {year}, Livetain | v{version}
            </FooterStyle>
        );
    }

}