import User from "Domain/Model/User/User";

export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';

interface IAuthenticationSuccessResponse {
    user: User,
    authentication: {
        accessToken: string,
        refreshToken: string,
    } | null
}

export const authenticateSuccess = ({ user, authentication = null }: IAuthenticationSuccessResponse) => ({
    type: AUTHENTICATE_SUCCESS,
    payload: {
        user,
        authentication,
    },
});
