import { AUTHENTICATE_SUCCESS, showInfoNotification } from 'Redux/Action';
import { ofType } from 'redux-observable';
import {mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TEpic } from "Redux/Config";

export const authenticateSuccessEpic: TEpic = (action$, store$ ) => {
    return action$.pipe(
        ofType(AUTHENTICATE_SUCCESS),
        mergeMap(() => {
            return of( showInfoNotification(`Willkommen im Backend von Livetain!` ));
        })
    );
}
