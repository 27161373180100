import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_SPOT_REQUEST,
    FIND_SPOTS_REQUEST,
    SEARCH_SPOTS_REQUEST,
    CREATE_SPOT_REQUEST,
    UPDATE_SPOT_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isSpotLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.spot.openRequests, [
        FIND_SPOT_REQUEST,
        FIND_SPOTS_REQUEST,
        SEARCH_SPOTS_REQUEST,
        CREATE_SPOT_REQUEST,
        UPDATE_SPOT_REQUEST,
    ]);