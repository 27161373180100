export const RELOAD_SERVER_SETTINGS_SUCCESS = 'RELOAD_SERVER_SETTINGS_SUCCESS';

interface IGetSettingsSuccess {
    options: Record<string, unknown>,
}

export const reloadServerSettingsSuccess = ( payload: IGetSettingsSuccess ) => ({
    type: RELOAD_SERVER_SETTINGS_SUCCESS,
    payload,
});
