import { parseQueryArguments } from "ClientService";


interface IListDefaultStateProps {
    location?: {
        hash: string,
        search: string,
    },
    page?: number,
    take?: number,
}

interface IListDefaultStateResponse {
    page: number,
    take: number,
    willBeLoadData: boolean,
    filter: { field: string, value: string|number }[],
    sorting: {
        field: string|undefined,
        order: TOrder|undefined,
    },
}

type TOrder = 'ASC' | 'DESC';

export const getListDefaultState = (props: IListDefaultStateProps): IListDefaultStateResponse => {

    let queryArguments: Record<string, string|number> = {};
    if(props.location && props.location.search){
        queryArguments = parseQueryArguments(props.location.search + props.location.hash);
    }
    const filter = [];

    for(const queryKey in queryArguments) {
        const value = queryArguments[queryKey];
        if(!queryKey.match('filter_')) {
            continue;
        }
        const key   = queryKey.replace('filter_', '');
        filter.push({
            field: key,
            value,
        })
    }

    if(!!queryArguments.page) {
        queryArguments.page = parseInt(queryArguments.page.toString(), 10);
    }
    if(!!queryArguments.take) {
        queryArguments.take = parseInt(queryArguments.take.toString(), 10);
    }


    return {
        page: (queryArguments.page as number) || props.page ||  1,
        take: (queryArguments.take as number) || props.take || 10,
        willBeLoadData: false,
        filter,
        sorting: {
            field: (queryArguments.field as string) || undefined,
            order: (['ASC', 'DESC'].includes(`${queryArguments.order}`)) ? (queryArguments.order as TOrder) : undefined,
        },
    };
};