import { combineEpics } from 'redux-observable';

import { createSpotListEpic } from './CreateSpotList/CreateSpotListEpic';
import { deleteSpotListEpic } from './DeleteSpotList/DeleteSpotListEpic';
import { findSpotListsEpic }  from './FindSpotLists/FindSpotListsEpic';
import { findSpotListEpic }    from './FindSpotList/FindSpotListEpic';
import { searchSpotListsEpic } from './SearchSpotLists/SearchSpotListsEpic';
import { updateSpotListEpic }  from './UpdateSpotList/UpdateSpotListEpic';


export default combineEpics(
    createSpotListEpic,
    deleteSpotListEpic,
    findSpotListsEpic,
    findSpotListEpic,
    searchSpotListsEpic,
    updateSpotListEpic,
);
