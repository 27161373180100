import React from 'react';
import Icon from "Component/Common/Icon/Icon";
import Wrapper from './Clock.style';
import { Subject, interval } from "rxjs";
import { takeUntil, timeInterval } from "rxjs/operators";


interface IProps {
    className?: string
}

type TProps = IProps;

type TState = {
    date: Date,
}

export default class Clock extends React.Component<TProps, TState> {

    onTick$    = interval(1000);
    onDestroy$ = new Subject();

    constructor(props: TProps) {
        super(props);

        this.state = {
            date: new Date(),
        }
    }

    shouldComponentUpdate(nextProps: TProps, nextState: TState) {
        return this.state.date.getMinutes() !== nextState.date.getMinutes();
    }

    componentDidMount() {
        this.onTick$.pipe(takeUntil(this.onDestroy$), timeInterval()).subscribe(() => {
            this.setState(() => ({ date: new Date() }));
        });
    }

    componentWillUnmount() {
        this.onDestroy$.next();
    }

    strPad( str: number ) {
        return `${str}`.padStart(2, '0');
    }

    renderDate() {
        return (
            <React.Fragment>
                { this.strPad(this.state.date.getDate()) }.
                { this.strPad(this.state.date.getMonth() + 1) }.
                { this.strPad(this.state.date.getFullYear()) }
                <span className="pipe">|</span>
                { this.strPad(this.state.date.getHours()) }:
                { this.strPad(this.state.date.getMinutes()) }
                &nbsp;Uhr
            </React.Fragment>
        );
    }

    render () {
        const { className = '' } = this.props;
        return (
            <Wrapper className={className} >
                <Icon type="clock-circle" />
                <span>
                    { this.renderDate() }
                </span>
            </Wrapper>
        );
    }

}