
export enum PageEvents {
    // public
    LOGIN_PAGE         = 'LOGIN_PAGE',


    // private
    DASHBOARD_PAGE     = 'DASHBOARD_PAGE',
    LOGOUT_PAGE        = 'LOGOUT_PAGE',
    SPOT_LISTS_PAGE    = 'SPOT_LISTS_PAGE',
    SPOT_LIST_PAGE     = 'SPOT_LIST_PAGE',
    SETTINGS_PAGE      = 'SETTINGS_PAGE',

    SPOTS_PAGE    = 'SPOTS_PAGE',
    SPOT_PAGE     = 'SPOT_PAGE',
    TAGS_PAGE     = 'TAGS_PAGE',

};