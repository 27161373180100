import React from "react";
import {PublicRoutes, PrivateRoutes, IRoute} from 'AppConfig/Routes';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

type IArguments = { [key:string]: any }

class LinkHandler {

    byPageKey: Record<string, {}> = {};

    constructor() {
        for(const key in PublicRoutes) {
            const route = PublicRoutes[key];
            this.byPageKey[route.pageKey] = { ...route, key };
        }
        for(const key in PrivateRoutes) {
            const route = PrivateRoutes[key];
            this.byPageKey[route.pageKey] = { ...route, key };
        }
    }

    get(routeKey: string, args: IArguments = {}, internal = true) {
        let route: IRoute = PrivateRoutes[routeKey];
        if(!internal || !route){
            route = PublicRoutes[routeKey];
        }
        if(!route || !route.path){
            return routeKey;
        }

        return this.getUrlWithArguments(route.path, args, route);
    }

    getUrlWithArguments(path: string, args: IArguments = {}, route: IRoute|null = null): string {
        if(route !== null) {
            // noinspection JSAnnotator
            if(!isEmpty(args) && route.arguments){
                // noinspection JSAnnotator
                route.arguments.forEach((argument: string) => {
                    if(!args[argument])  {
                        console.warn(`the route expected the following argument: ${argument}`);
                        return null;
                    }
                    path += `/${args[argument]}`;
                });
            }
        }

        for(const key in args) {
            const value = args[key];
            if(path.match(`:${key}`)){
                path = path.replace(`:${key}`, value);
            }
        }

        return path;
    }

    redirect(key: string, args: IArguments = {}, hard = false): React.ReactElement|void {
        if(hard) {
            window.location.href = this.get( key, args );
            return;
        }

        return <Redirect to={`${this.get( key, args )}`} />;
    }

    convertRouteByKey(key: string) {
        const route  = PrivateRoutes[key];

        return Object.assign({},
            { ...route },
            { key },
            { ...route.sideboard },
            { sorting: (route.sideboard && route.sideboard.sorting) ? route.sideboard.sorting : 1000 },
        );
    }

    getSideBoard() {
        const sideBoard = [];

        for(const key in PrivateRoutes){
            const route = PrivateRoutes[key];

            if(route.hasOwnProperty('sideboard')) {
                sideBoard.push(this.convertRouteByKey(key));
            }
        }

        const orderSideBar = ( a:any, b: any ) =>
            (a.sorting > b.sorting) ? 1 : ((b.sorting > a.sorting) ? -1 : 0);

        return sideBoard.sort(orderSideBar);
    }

    findByPageKey(pageKey: string) {
        return this.byPageKey[pageKey] || null;
    }

}

const linkHandler = new LinkHandler();

export default linkHandler;