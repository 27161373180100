export const parseQueryArguments = (queryString: string = ''): Record<string, string> => {
    const str  = queryString.replace(/\?/, '');
    const splitString = str.split('&');

    const args: Record<string, string> = {};

    splitString.forEach(arg => {
        const tmp: string[] = arg.split('=');
        args[tmp[0]] = tmp[1];
    });

    return args;
};