import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_SPOT_FAILURE,
    UPDATE_SPOT_FAILURE,
    FIND_SPOT_FAILURE,
    FIND_SPOTS_FAILURE,
    SEARCH_SPOTS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isSpotFailed = (state: IReduxStore): boolean => ArrayContains(state.spot.failedRequests, [
    CREATE_SPOT_FAILURE,
    UPDATE_SPOT_FAILURE,
    FIND_SPOT_FAILURE,
    FIND_SPOTS_FAILURE,
    SEARCH_SPOTS_FAILURE,
]);