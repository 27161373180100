import UserRepository     from './UserRepository/UserRepository';
import SpotListRepository from './SpotListRepository/SpotListRepository';
import SpotRepository     from './SpotRepository/SpotRepository';
import ImageRepository    from './ImageRepository/ImageRepository';
import SettingRepository  from './SettingRepository/SettingRepository';
import TagRepository      from './TagRepository/TagRepository';
import LanguageRepository from './LanguageRepository/LanguageRepository';


const repositories = {
    UserRepository,
    SpotListRepository,
    SpotRepository,
    ImageRepository,
    SettingRepository,
    TagRepository,
    LanguageRepository,
};

export default repositories;