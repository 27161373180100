import { globalReducer }   from './Global/GlobalReducer';
import { userReducer }     from './User/UserReducer';
import { spotListReducer } from './SpotList/SpotListReducer';
import { spotReducer }     from './Spot/SpotReducer';
import { persistReducer }  from './Persist/PersistReducer';
import { tagReducer }      from './Tag/TagReducer';

const reducer = {
    global  : globalReducer,
    user    : userReducer,
    spotList: spotListReducer,
    spot    : spotReducer,
    persist : persistReducer,
    tag     : tagReducer,
};

export default reducer;