import { BaseModel }                   from 'Domain/Model/BaseModel/BaseModel';
import { ITranslationOverlay }         from 'Domain/Model/Language/Language';


export interface ITag {
    id: string,
    name: string,
    createdAt: number,

    overlayTexts?: { field: string, text: string, languageId: string }[],
}

export class Tag extends BaseModel {

    public id: string;
    public name: string;
    public createdAt: number;
    public textOverlay?: Record<string, ITranslationOverlay>;


    constructor(data: ITag) {
        super();

        this.id        = data.id;
        this.name      = data.name;
        this.createdAt = data.createdAt;

        this.textOverlay = {};

        if(data.overlayTexts) {
            data.overlayTexts.forEach(entry => {
                this.textOverlay![entry.languageId] = {
                    ...(this.textOverlay![entry.languageId] || {}),
                    [entry.field]: entry.text,
                };
            })
        }
    }

}