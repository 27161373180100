import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";

export const addSingleObjectToReducer = (Store, singleObject) => {
    const baseStore = cloneDeep(Store);
    let objectKey   = null;
    let baseObject  = null;

    const addToReducer = () => {
        if(!objectKey || !baseObject) {
            return false;
        }

        if(baseStore.hasOwnProperty(objectKey)) {
            for(const key in baseObject) {
                if(key === 'createdAt') {
                    continue;
                }
                if(baseObject.hasOwnProperty(key)) {
                    baseStore[objectKey][key] = baseObject[key];
                }
            }
        }
        else {
            baseStore[objectKey] = baseObject;
        }


        return true;
    };

    if(isObject(singleObject)) {
        const keys = Object.keys(singleObject);
        if(keys.length === 1) {
            objectKey  = keys[0];
            baseObject = singleObject[objectKey];
        }
        else if(keys.length > 1 && keys.includes('id')) {
            objectKey  = singleObject.id;
            baseObject = singleObject;
        }

        addToReducer();
    }
    else if(Array.isArray(singleObject) && singleObject.length === 1) {
        return addSingleObjectToReducer(Store, { ...singleObject });
    }

    return baseStore;
};