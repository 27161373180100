import { SEARCH_TAGS_REQUEST, searchTagsSuccess, searchTagsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const searchTagsEpic: TEpic = (action$, store$, { TagRepository }) => {
    return action$.pipe(
        ofType( SEARCH_TAGS_REQUEST ),
        mergeMap(action => from(
            TagRepository.findAll(action.payload)
                .then(({ tags, meta }) => searchTagsSuccess({ tags, response: meta, request: action}))
                .catch((error: Error) => searchTagsFailure(error)),
        )),
    );
}
