import {
    AUTHENTICATE_REQUEST,
    AUTHENTICATE_SUCCESS,
    AUTHENTICATE_FAILURE,

    LOGOUT_USER,
    APPLICATION_BOOTSTRAPPED,

} from 'Redux/Action';
import { TUserState, UserState } from 'Redux/Store/User/UserState';
import { Reducer } from "redux";

const initialState = UserState.getInitialState();

export const userReducer: Reducer<TUserState> = (state = initialState, action) => {
    switch (action.type) {

    case LOGOUT_USER :
    case AUTHENTICATE_FAILURE :
        return {
            ...initialState,
        };


    case AUTHENTICATE_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: [],
            updatedAt: Date.now(),
            error: false,
        };

    case APPLICATION_BOOTSTRAPPED:
        return {
            ...state,
            openRequests: [],
            failedRequests: [],
            updatedAt: Date.now(),
            error: false,
        };


    case AUTHENTICATE_SUCCESS: {
        return {
            ...state,
            openRequests: [],
            updatedAt: Date.now(),
            user: action.payload.user,
            authentication: (action.payload.authentication) ? action.payload.authentication : state.authentication,
        };
    }

    default:
        return state;
    }
};
