import isObject from "lodash/isObject";
import { ERROR_MESSAGES } from "AppConfig/Messages/ErrorMessages";

interface IErrorObject {
    data: {
        internalMessage: string,
        message: string,
        message_code: string,
        errors?: [],
    },
}

interface IErrorResponse {
    message: string,
    code: string,
}

export default function defaultErrorMessages(errorObject: IErrorObject): IErrorResponse {
    let message = '';

    if(!isObject(errorObject)) {
        return errorObject;
    }

    if(errorObject.data) {
        if(!!errorObject.data.internalMessage) {
            return { message: errorObject.data.internalMessage, code: 'HANDLED_BY_SERVER' }
        }


        if(ERROR_MESSAGES[errorObject.data.message]) {
            return { message: ERROR_MESSAGES[errorObject.data.message], code: errorObject.data.message };
        }
        if(ERROR_MESSAGES[errorObject.data.message_code]) {
            return { message: ERROR_MESSAGES[errorObject.data.message_code], code: errorObject.data.message_code };
        }

        if(errorObject.data.message){
            message = `Serverfehler: "${errorObject.data.message}" Bitte nehmen Sie Kontakt mit unseren IT-Support auf.`;
        }
    }

    return { message, code: 'UNHANDLED_ERROR' };

}