import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingMessage from "Component/Common/LoadingMessage/LoadingMessage";
import { ConnectedRouter } from 'connected-react-router'
import { connect, MapDispatchToPropsNonObject, MapStateToProps } from 'react-redux';
import { linkHandler }  from "ClientService";
import { IRoute, PrivateRoutes, PublicRoutes } from "AppConfig/Routes";
import App from 'Component/Application/App';
import { history } from 'Redux/Config';
import PublicApp from "Component/Application/PublicApp/PublicApp";
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { IReduxStore } from "Redux/Store/IReduxStore";
import { RouteComponentProps } from "react-router";
import { isLoggedIn} from "Redux/Query";
import { logoutUser } from "Redux/Action";
import { Layout } from "antd";

interface IDispatchProps {
    logoutUser: () => void,
}

interface IStateProps {
    isLoggedIn: boolean,
}

interface IProps {
    hasNetworkError: boolean,
    isAuthenticationRequest: boolean,
    router: RouteComponentProps,
}

type TProps = IDispatchProps & IStateProps & IProps & {

};

type TState = {
    isLoggedIn: boolean,
}

class Router extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);

        this.state = {
            isLoggedIn: props.isLoggedIn || false,
        }
    }

    componentDidUpdate(prevProps: TProps, prevState: IStateProps) {
        if(this.state.isLoggedIn && prevState.isLoggedIn && this.props.router.location.pathname === linkHandler.get('login')) {
            this.props.logoutUser();
        }
        if(this.props.isLoggedIn !== prevProps.isLoggedIn) {
            this.setState(() => ({ isLoggedIn: this.props.isLoggedIn }));
        }
    }

    addRoutes = (routes: Record<string, IRoute>) => {
        const routesView: React.ReactElement[] = [];

        for(const key in routes){
            const route      = routes[key];
            const routeProps = {
                exact: route.exact || true,
                path: route.path   || linkHandler.get('login'),
                component: route.component
            };

            if(route.arguments) {
                // noinspection JSAnnotator
                routeProps.path += `/:${route.arguments.join('/:')}`;
            }

            routesView.push(<Route key={`route_${key}`} {...routeProps} />);
        }

        return routesView;
    }

    renderIsLoggedIn = () => {
        const { pathname } = this.props.router.location;
        if(!this.props.isLoggedIn || (this.props.isLoggedIn && pathname !== linkHandler.get('login'))) {
            return null;
        }

        if( this.state.isLoggedIn && pathname === linkHandler.get('login')) {
            this.props.logoutUser();
        }
        return <Redirect to={ ( this.state.isLoggedIn && pathname === linkHandler.get('login')) ? linkHandler.get('login') : linkHandler.get('dashboard') } />
    }

    renderHasNetworkError = () => {
        if(!this.props.hasNetworkError) {
            return null;
        }

        return <Redirect to={ linkHandler.get('networkError') } />
    }


    renderFallBack = () => {
        if(this.props.isLoggedIn) {
            return (
                <Layout>
                    <LoadingMessage />
                </Layout>
            );
        }
        return <LoadingMessage />;
    }

    render() {
        const { isLoggedIn } = this.props;
        let routesView = this.addRoutes(PublicRoutes);
        if( isLoggedIn ) {
            routesView = [ ...routesView, ...this.addRoutes(PrivateRoutes) ];
        }

        if(this.props.isAuthenticationRequest) {
            return <LoadingMessage />;
        }

        return(
            <Suspense fallback={ this.renderFallBack() }>
                <ConnectedRouter history={ history }>
                    { isLoggedIn ?
                        <App>
                            <Switch>
                                { this.renderHasNetworkError() }
                                { this.renderIsLoggedIn() }

                                { routesView }
                                <Redirect to={`404`} />
                            </Switch>
                        </App> :
                        <PublicApp>
                            <Switch>
                                { this.renderHasNetworkError() }

                                { routesView }
                                <Redirect
                                    to={{
                                        pathname: linkHandler.get('login'),
                                        state: { from: history.location.pathname },
                                    }}
                                />
                            </Switch>
                        </PublicApp>
                    }

                </ConnectedRouter>
            </Suspense>
        );
    }

}

const mapStateToProps: MapStateToProps<IStateProps, {}, IReduxStore> = (state) => {
    return {
        hasNetworkError: false,
        isLoggedIn: isLoggedIn(state),
        router: state.router,
        isAuthenticationRequest: state.user.openRequests.includes('AUTHENTICATE_REQUEST'),
    };
}

const mapDispatchToProps: MapDispatchToPropsNonObject<IDispatchProps, RouteComponentProps> = (dispatch) => {
    return bindActionCreators({
        logoutUser,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Router);
