import {
    RELOAD_SERVER_SETTINGS_REQUEST,
    reloadServerSettingsSuccess,
    reloadServerSettingsFailure
}                               from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from, of }             from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { TEpic }                from 'Redux/Config';

export const reloadServerSettingsEpic: TEpic = (action$, store$, { SettingRepository }) => {
    return action$.pipe(
        ofType(RELOAD_SERVER_SETTINGS_REQUEST),
        mergeMap(action => {
            return from(SettingRepository.reloadSettings()).pipe(
                mergeMap(({ options }) => {
                    return from([
                        reloadServerSettingsSuccess({ options })
                    ]);
                }),
                catchError((error: Error) => of(reloadServerSettingsFailure(error)))
            );
        })
    );
};
