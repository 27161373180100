import isObject from "lodash/isObject";

export const getSingleObject = (singleObject) => {
    if(isObject(singleObject)) {
        const keys = Object.keys(singleObject);
        if(keys.length >= 1) {
            if(keys.includes('id')) {
                return singleObject;
            }
            return singleObject[keys[0]];
        }
    }
    else if(Array.isArray(singleObject) && singleObject.length >= 1) {
        return singleObject[0];
    }

    return null;
};