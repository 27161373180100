import { combineEpics } from 'redux-observable';

import { authenticateRequestEpic } from './Authenticate/AuthenticateRequestEpic';
import { authenticateSuccessEpic } from './Authenticate/AuthenticateSuccessEpic';
import { checkSessionRequestEpic } from './CheckSession/CheckSessionRequestEpic';

export default combineEpics(

    authenticateRequestEpic,
    authenticateSuccessEpic,
    checkSessionRequestEpic,

);
