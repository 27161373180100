import { combineEpics } from "redux-observable";
import { showErrorNotificationEpic }   from "./ShowErrorNotification/ShowErrorNotificationEpic";
import { showWarningNotificationEpic } from "./ShowWarningNotification/ShowWarningNotificationEpic";
import { showSuccessNotificationEpic } from "./ShowSuccessNotification/ShowSuccessNotificationEpic";
import { showInfoNotificationEpic }    from "./ShowInfoNotification/ShowInfoNotificationEpic";

export default combineEpics(
    showErrorNotificationEpic,
    showWarningNotificationEpic,
    showInfoNotificationEpic,
    showSuccessNotificationEpic
)