export const removeOpenRequest = (action, state) => {
    const openRequests = [...state.openRequests];
    let actionType = '';
    if(action.type.match('SUCCESS')) {
        actionType = action.type.replace('SUCCESS', 'REQUEST');

    }
    else if(action.type.match('FAILURE')) {
        actionType = action.type.replace('FAILURE', 'REQUEST');
    }
    else {
        return openRequests;
    }

    let index = openRequests.indexOf(actionType);
    while(index > -1){
        openRequests.splice(index, 1);
        index = openRequests.indexOf(actionType);
    }
    return openRequests;
};