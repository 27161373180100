import { BaseModel }     from "Domain/Model/BaseModel/BaseModel";

export interface ILanguage {
    id:   string,
    name: string,
    key:  string,
}


export interface ITranslationOverlay  {
    name: string|null,
    description: string|null,
}

export class Language extends BaseModel {

    public id: string;
    public name: string;
    public key: string;


    constructor(data: ILanguage) {
        super();

        this.id   = data.id;
        this.name = data.name;
        this.key  = data.key;
    }

}