import {
    CREATE_TAG_REQUEST,
    CREATE_TAG_SUCCESS,
    CREATE_TAG_FAILURE,

    DELETE_TAG_REQUEST,
    DELETE_TAG_SUCCESS,
    DELETE_TAG_FAILURE,

    UPDATE_TAG_REQUEST,
    UPDATE_TAG_SUCCESS,
    UPDATE_TAG_FAILURE,

    FIND_TAG_REQUEST,
    FIND_TAG_SUCCESS,
    FIND_TAG_FAILURE,

    FIND_TAGS_REQUEST,
    FIND_TAGS_SUCCESS,
    FIND_TAGS_FAILURE,

    SEARCH_TAGS_REQUEST,
    SEARCH_TAGS_SUCCESS,
    SEARCH_TAGS_FAILURE,
    
    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer } from "redux";
import { TagState, TTagState } from "Redux/Store/Tag/TagState";

const initialState = TagState.getInitialState();

export const tagReducer: Reducer<TTagState> = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case CREATE_TAG_REQUEST:
    case DELETE_TAG_REQUEST:
    case UPDATE_TAG_REQUEST:
    case FIND_TAG_REQUEST:
    case FIND_TAGS_REQUEST:
    case SEARCH_TAGS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_TAG_SUCCESS:
    case UPDATE_TAG_SUCCESS:
    case FIND_TAG_SUCCESS:
        const entry = getSingleObject(action.payload);

        return {
            ...state,
            lastInteractiveId: entry.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            tag: {
                ...state.tag,
                entries: addSingleObjectToReducer(state.tag.entries, entry)
            },
        };

    case FIND_TAGS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            tag: {
                entries: action.payload.tags,
                maxCount: (action.meta) ? action.meta.response.count : state.tag.maxCount,
            },
            updatedAt: Date.now(),
        };

    case SEARCH_TAGS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            searchEntries: {
                ...state.searchEntries,
                ...action.payload.tags,
            },
            updatedAt: Date.now(),
        };

    case DELETE_TAG_SUCCESS: {
        const entries = { ...state.tag.entries };
        delete entries[action.payload.id];
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            tag: {
                ...state.tag,
                entries: entries,
            },
        };
    }

    case CREATE_TAG_FAILURE:
    case DELETE_TAG_FAILURE:
    case UPDATE_TAG_FAILURE:
    case FIND_TAG_FAILURE:
    case FIND_TAGS_FAILURE:
    case SEARCH_TAGS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
