export interface IOption {

    id:    string;
    name:  string;
    value: string;

}

export class Option {

    public id:    string;
    public name:  string;
    public value: string;

    constructor( data: IOption  ) {

        this.id    = data.id;
        this.name  = data.name;
        this.value = data.value;

    }

}