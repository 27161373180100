import { BaseStoreNamespace }  from 'Redux/Store/BaseStore';
import { IUser } from "Domain/Model/User/User";

interface IUserState {
    authentication: {
        accessToken?: string|null,
        refreshToken?: string|null,
        accessTokenExpiresAt?: string|null,
        refreshTokenExpiresAt?: string|null,
    },
    user: IUser,
}

export type TUserState = IUserState & BaseStoreNamespace.TDefaultState;


export const UserState = {

    getInitialState: (): TUserState => ({
        updatedAt                : 0,
        openRequests             : [],
        failedRequests           : [],
        authentication: {
            accessToken: null,
            refreshToken: null,
            accessTokenExpiresAt: null,
            refreshTokenExpiresAt: null,
        },
        user: {
            id: '',
            mail: '',
            title: '',
            firstName: '',
            lastName: '',
            phone: '',
            name: '',
        }
    })

}