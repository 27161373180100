import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Spot }   from 'Domain/Model/Spot/Spot';

const config = {
    model: Spot,
    baseRoute: '/spot',
    singular: 'spot',
    plural: 'spots',
}

class SpotRepository extends BaseRepository<Spot, typeof config.singular, typeof config.plural> {

}

export default new SpotRepository(config);
