import moment from "moment";

export const parseDate = ( str: string|number ) => {
    if(!str || `${str}` === '---') {
        return null;
    }

    if(typeof str === 'object') {
        return moment(str);
    }

    let timeParts = [`${str}`];
    if(!!`${str}`.match(/\|/i)) {
        timeParts = `${str}`.split('|');
    }
    const tmpParts = [ ...timeParts ];
    tmpParts.forEach((part, index) => {
        if(!!`${part}`.match(/\./i)) {
            const tmp = part.trim().split('.');
            timeParts.splice(timeParts.indexOf(part), 1);
            timeParts = [ ...tmp, ...timeParts];
        }
        if(!!`${part}`.match(/:/i)) {
            const tmp = part.trim().split(':');
            timeParts.splice(timeParts.indexOf(part), 1);
            timeParts = [ ...timeParts, ...tmp];
        }
    });

    const date = new Date();
    switch(timeParts.length) {
        case 6  : date.setSeconds(parseInt(timeParts[5])); // falls through
        case 5  : date.setMinutes(parseInt(timeParts[4])); // falls through
        case 4  : date.setHours(parseInt(timeParts[3]));   // falls through
        case 3  :
            date.setDate(parseInt(timeParts[0]));
            date.setMonth(parseInt(timeParts[1]) - 1);
            date.setFullYear(parseInt(timeParts[2]));
            if(timeParts.length < 4) {
                date.setSeconds(0);
                date.setMinutes(0);
                date.setHours(0);
            }
            break;
        default : break;
    }

    return moment(date);
};

