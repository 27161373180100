import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_TAG_FAILURE,
    UPDATE_TAG_FAILURE,
    FIND_TAG_FAILURE,
    FIND_TAGS_FAILURE,
    SEARCH_TAGS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isTagsFailed = (state: IReduxStore): boolean => ArrayContains(state.tag.failedRequests, [
    CREATE_TAG_FAILURE,
    UPDATE_TAG_FAILURE,
    FIND_TAG_FAILURE,
    FIND_TAGS_FAILURE,
    SEARCH_TAGS_FAILURE,
]);