import { combineEpics } from 'redux-observable';

import { createTagEpic }  from './CreateTag/CreateTagEpic';
import { deleteTagEpic }  from './DeleteTag/DeleteTagEpic';
import { findTagsEpic }   from './FindTags/FindTagsEpic';
import { findTagEpic }    from './FindTag/FindTagEpic';
import { searchTagsEpic } from './SearchTags/SearchTagsEpic';
import { updateTagEpic }  from './UpdateTag/UpdateTagEpic';


export default combineEpics(
    createTagEpic,
    deleteTagEpic,
    findTagsEpic,
    findTagEpic,
    searchTagsEpic,
    updateTagEpic,
);
