import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Tag } from "Domain/Model/Tag/Tag";

export const getTags = (state: IReduxStore, filter: null|IFilterProps<Tag> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<Tag>;
    }

    return filterObjectStore(state.tag.tag.entries, filter);
};
