import React, { Component, Suspense } from 'react';
import {connect, MapDispatchToPropsNonObject, MapStateToProps} from 'react-redux';
import { bindActionCreators } from 'redux';
import Wrapper from './Sideboard.style';
import { linkHandler, isDifferent } from 'ClientService';
import { Menu } from 'antd';
import Icon from 'Component/Common/Icon/Icon';
import Link from 'Component/Common/Link/Link';
import { WINDOW_TYPES } from 'AppConfig/Config/WindowTypes';
import { logoutUser } from 'Redux/Action';
import {getActiveMenuTab } from "Redux/Query";
import {IReduxStore} from "Redux/Store/IReduxStore";

interface IDispatchProps {
    logout: () => void,
}

interface IStateProps {
    activeTab: string,
}

interface IProps {
    windowMode?: string,
}

type TProps = IProps & IDispatchProps & IStateProps;

type TState = {
    collapsed: boolean,
    selected: string,
}

class Sideboard extends Component<TProps, TState> {

    public sideBoard: any;

    constructor(props: TProps) {
        super(props);

        this.sideBoard = linkHandler.getSideBoard();

        this.state = {
            collapsed: (props.windowMode !== WINDOW_TYPES.DESKTOP),
            selected: this.sideBoard[0].key as string,
        };
    }

    componentDidMount() {
        this.setState(() => ({ selected: this.props.activeTab }));
    }

    componentDidUpdate(prevProps: TProps) {
        const { activeTab } = prevProps;
        if(isDifferent(activeTab, this.props.activeTab)) {
            this.setState(() => ({ selected: this.props.activeTab }));
        }
    }

    handleLogout = (evt: any) => {
        evt.preventDefault();
        this.props.logout();
    };

    onChangeHandler = ({ key }: any) => {
        this.setState(() => ({ selected: key }));
    };

    renderMenuEntries() {
        return this.sideBoard.map( (entry: any) => {
            if(entry.key === 'logout') {
                return (
                    <Menu.Item key={entry.key} className="logoutButton">
                        <Link to={linkHandler.get(entry.key)} onClick={ this.handleLogout }>
                            {(entry.icon) ? <Icon type={entry.icon} />  : null }
                            <span>{entry.label}</span>
                        </Link>
                    </Menu.Item>
                );
            }

            const renderItem = (entry: any) => {
                let SideboardAdditionalComponent = null;
                if(entry.sideboard.hasOwnProperty('render')) {
                    SideboardAdditionalComponent = entry.sideboard.render
                }
                return (
                    <React.Fragment>
                        {(entry.icon) ? <Icon type={entry.icon} />  : null }
                        <span>{entry.label} { SideboardAdditionalComponent ?
                            <Suspense fallback={ null }>
                                <SideboardAdditionalComponent />
                            </Suspense>
                            : null }
                        </span>
                    </React.Fragment>
                );
            }

            return (
                <Menu.Item key={entry.key}>
                    { entry.externalLink  ?
                        <a href={ entry.externalLink } target="_blank" rel="noopener noreferrer">
                            { renderItem(entry) }
                        </a> :
                        <Link to={linkHandler.get(entry.key, entry.defaultArgs || {})}>
                            { renderItem(entry) }
                        </Link>
                    }

                </Menu.Item>
            );
        });
    }


    render() {
        return (
            <Wrapper width="256">
                <div className="sidebarLogo">
                    <h3 className="txtWhite">Livetain Admin-Bereich</h3>
                </div>
                <Menu
                    selectedKeys={[ this.state.selected ]}
                    onClick={ this.onChangeHandler }
                >
                    { this.renderMenuEntries() }
                </Menu>
            </Wrapper>
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IProps, IReduxStore> = (state) => {
    return {
        activeTab: getActiveMenuTab(state),
    };
}

const mapDispatchToProps: MapDispatchToPropsNonObject<IDispatchProps, IProps> = (dispatch) => {
    return bindActionCreators(
        {
            logout: logoutUser,
        },
        dispatch,
    );
}

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps, ownProps: IProps) => {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
};


export default connect(mapStateToProps, mapDispatchToProps,  mergeProps)(Sideboard);
