import { requestApi } from "ClientService";
import isEmpty    from 'lodash/isEmpty';
import each       from 'lodash/each';
import { ApiResponse } from "apisauce";


interface IRepositoryConstructor<TModel, TSingular, TPlural> {
    model: { new(data: any): TModel },
    baseRoute: string,
    singular: TSingular,
    plural: TPlural,
    overrideRoutes?: {[key:string]: string}
}

export interface IFilterSettings {
    filter: IFilter[];
    pagination?: IPagination,
    sorting?: {
        field: string,
        order: TSortDirection,
    }
}

interface IRequestArguments<TCallbackReturn> {
    path?: string|null,
    data?: any,
    type?: string,
    callback: (responseData: any, response: ApiResponse<any>) => TCallbackReturn,
}

/**
 * @type TQueryFilter
 * query to filter entities
 */
export type TQueryFilter = Partial<ISortable & IPagination>;
/**
 * @interface ISortable
 * query to sort elements
 */
export interface ISortable extends IPagination {
    /** the key of the entity to search for */
    sortField: string;
    /** sort direction */
    sortDirection: TSortDirection;
}

type TSortDirection = 'ASC'|'DESC';

/**
 * @interface IPagination
 * defines a paginatable query
 */
export interface IPagination {
    /** current page */
    page?: number;
    /** number of elements to take */
    take: number;
}
/**
 * @interface IFilterable
 * filters by the keys, you can define nested keys as an array
 */
export interface IFilterable {
    filter: IFilter[];
}
export interface IFilter {
    field?: string;
    value?: string | number | boolean;
    noWildcard?: boolean;
}

// eslint-disable-next-line
type TSingularResponse<TSingular extends string, TModel> = Record<string, TModel>
type TPluralResponse<TPlural extends string, TModel> = Record<TPlural, Record<string, TModel>> & { meta: any }


export default class BaseRepository<TModel extends { id: string|number }, TSingular extends string, TPlural extends string> {

    public model: { new(data: any): TModel };
    public filterSettings = {};
    public requestApi: any   = requestApi;
    public baseRoute: string = '/';
    public singular:  TSingular;
    public plural: TPlural;
    public overrideRoutes: {[key:string]: string} = {};

    constructor({ model, baseRoute, singular, plural, overrideRoutes = {}  }: IRepositoryConstructor<TModel, TSingular, TPlural>) {
        this.baseRoute = baseRoute;

        this.singular = singular;
        this.plural = plural;
        this.model = model;

        this.overrideRoutes = Object.assign({}, this.overrideRoutes, overrideRoutes);
    }

    getFilterSettings = (): TQueryFilter => {
        const filterSettings = Object.assign({}, this.filterSettings);
        this.filterSettings  = {};

        return filterSettings;
    };

    setFilterSettings = ( settings: IFilterSettings ) => {
        let filterSettings: TQueryFilter & Record<string, unknown> = {
            sortField: 'id',
            sortDirection: 'ASC',
            page:  1,
            take: 50,
        };

        if(settings.filter) {
            settings.filter.forEach(filter => filterSettings[`filter_${filter.field}`] = filter.value );
        }

        if(settings.pagination) {
            filterSettings = Object.assign({}, filterSettings, settings.pagination);
        }

        if(settings.sorting) {
            filterSettings.sortField     = settings.sorting.field;
            filterSettings.sortDirection = settings.sorting.order;
        }

        this.filterSettings = filterSettings;

        return this;
    };


    findAll( filterSettings = { filter: [] }) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: this.overrideRoutes['findAll'] || this.baseRoute,
            data: this.getFilterSettings(),
            type: 'GET',
            callback: this.initializeModels
        });
    }

    findById( id: number ) {
        return this.makeRequest({
            path: (this.overrideRoutes['findById']) ? `${this.overrideRoutes['findById']}/${id}` : `${this.baseRoute}/${id}`,
            type: 'GET',
            callback: this.initializeSingleModel,
        });
    }

    findBy( data: any ) {
        return this.makeRequest({
            path: this.overrideRoutes['findBy'] || this.baseRoute,
            type: 'POST',
            data: data,
            callback: this.initializeModels
        });
    }

    remove( id: number ) {
        return this.makeRequest({
            path: (this.overrideRoutes['remove']) ? `${this.overrideRoutes['remove']}/${id}` : `${this.baseRoute}/${id}`,
            type: 'DELETE',
            callback: () => undefined
        });
    }

    delete( id: number ) {
        return this.remove( id );
    }

    update( id: number, data: any ) {
        return this.makeRequest({
            path: (this.overrideRoutes['update']) ? `${this.overrideRoutes['update']}/${id}` : `${this.baseRoute}/${id}`,
            data,
            type: 'PATCH',
            callback: this.initializeSingleModel,
        })
    }

    create( data: any ) {
        return this.makeRequest({
            path: this.overrideRoutes['create'] || this.baseRoute,
            data,
            type: 'POST',
            callback: this.initializeSingleModel,
        })
    }

    iterate = (dataEntries: [], initializeFunction = this.initializeModel): Record<string, TModel> => {
        const objectStorage: Record<string, TModel> = {};
        each(dataEntries, entry => {
            const newModel = initializeFunction(entry);
            objectStorage[newModel.id] = newModel;
        });
        return objectStorage;
    };

    initializeModel = ( entry: any ): TModel => {
        return new this.model( entry ) as TModel;
    };

    initializeSingleModel = ( response: any ): TSingularResponse<TSingular, TModel> => {
        if(isEmpty(response)) {
            return {};
        }

        const newModel  = this.initializeModel((response.hasOwnProperty(this.singular)) ? response[this.singular] : response);
        return { [newModel.id]: newModel };
    };

    initializeModels = ( response: any ): TPluralResponse<TPlural, TModel> => {
        if(isEmpty( response )) {
            return { [this.plural]: {}, meta: response.meta } as TPluralResponse<TPlural, TModel>;
        }

        if(!!this.plural && response.hasOwnProperty(this.plural) ) {
            return {
                [this.plural]: this.iterate( response[this.plural]),
                meta: response.meta,
            } as TPluralResponse<TPlural, TModel>;
        }
        return { [this.plural]: this.iterate( response ), meta: response.meta } as TPluralResponse<TPlural, TModel>;
    };

    makeRequest<TCallbackReturn>({ type = 'GET', path = this.baseRoute, data = null, callback }: IRequestArguments<TCallbackReturn>): Promise<TCallbackReturn> {

        return new Promise((resolve, reject) => {
            console.log(`REQUEST ${type}: `, path, data);

            this.requestApi[type.toLowerCase()](path, data)
                .then( (response: any) => {
                    if(response.ok && ( response.data.status === 'success' || response.status === 200)) {
                        console.log(`RESPONSE ${type}: `, path, response.data);
                        return resolve( callback( response.data, response ) );
                    }
                    console.error(`RESPONSE ERROR ${type}: `, path, response);
                    reject(response);
                })
                .catch( (error: Error) => {
                    console.error(`RESPONSE ERROR ${type}: `, path, error);
                    return reject(new Error('SYSTEM_ERROR - BaseRepository'));
                })
        });

    }

}