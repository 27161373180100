import { APPLICATION_BOOTSTRAPPED, authenticateSuccess, authenticateFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const checkSessionRequestEpic: TEpic = (action$, store$, { UserRepository }) => {
    return action$.pipe(
        ofType(APPLICATION_BOOTSTRAPPED),
        withLatestFrom(store$),
        mergeMap(([ action, store ]) => {
            const { user, authentication } = store.user;

            if(authentication.accessToken) {
                UserRepository.setAuthorisationHeader(authentication.accessToken);
                return from(UserRepository.checkAuthorization()).pipe(
                    mergeMap( ({ authentication}) => of(authenticateSuccess({ user, authentication }))),
                    catchError( error => {
                        return from(UserRepository.refreshAccessToken(authentication.refreshToken)).pipe(
                            mergeMap( refreshData => of(authenticateSuccess({ ...refreshData }))),
                            catchError( error => of(authenticateFailure(error))),
                        );

                    }),
                );
            }

            return EMPTY;

        }),
    );
}
