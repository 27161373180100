import React                 from 'react';
import LoadingMessageWrapper from './LoadingMessage.style';

export default class LoadingMessage extends React.Component {

    render() {
        return (
            <LoadingMessageWrapper>
                <div className="loaderRollerWrapper">
                    <div className="lds-roller">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>

                <div className="loadingMessage">
                    Wird geladen ...
                </div>
            </LoadingMessageWrapper>
        );
    }
}