import { BaseModel } from "Domain/Model/BaseModel/BaseModel";

export type TOrderSettings<TModel extends BaseModel> = {
    by: keyof TModel;
    order: string;
};

export interface IFilterProps<TModel extends BaseModel> {
    limit?: number,
    sorting?: TOrderSettings<TModel>,
}

export type TObjectStore<TModel extends BaseModel> = Record<string, TModel>;


export const queryFilter = <TModel extends BaseModel>(props: IFilterProps<TModel>): IFilterProps<TModel>|undefined => {
    let filter: IFilterProps<TModel>|undefined;

    if(props.hasOwnProperty('limit')) {
        if(!filter) { filter = {}; }
        filter.limit = props.limit;
    }
    if(props.hasOwnProperty('sorting')) {
        if(!filter) { filter = {}; }
        filter.sorting = props.sorting;
    }

    return filter;
};

export const sortObjects = <TModel extends BaseModel>(objectStore: TObjectStore<TModel>, orderSettings: TOrderSettings<TModel>): TModel[] => {
    const orderBy        = orderSettings.by || 'id';
    const orderDirection = ((orderSettings.order || 'ASC') === 'ASC') ? 1 : -1;

    return Object.keys(objectStore).map(key => objectStore[key]).sort(( item1, item2 ) => {
        if (item1[orderBy]  < item2[orderBy]) {
            return -orderDirection;
        }
        if (item1[orderBy] > item2[orderBy]) {
            return orderDirection;
        }
        return 0;
    });

};


export const filterObjectStore = <TModel extends BaseModel>(objectStore: TObjectStore<TModel>, filter:IFilterProps<TModel>|null = null): TModel[] => {
    if(!objectStore) {
        return [];
    }

    if(filter === null) {
        return Object.keys(objectStore).map(key => objectStore[key]);
    }

    if(filter.hasOwnProperty('limit') && filter.sorting) {
        return sortObjects(objectStore, filter.sorting).slice(0, filter.limit);
    }
    if(filter.hasOwnProperty('limit')) {
        return Object.keys(objectStore).map(key => objectStore[key]).slice(0, filter.limit);
    }
    if(filter.sorting) {
        return sortObjects(objectStore, filter.sorting);
    }

    return Object.keys(objectStore).map(key => objectStore[key]);
};