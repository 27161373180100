import { lazy } from "react";
import {IRoute} from "./IRoute";
import {PageEvents} from "Component/Page/PageEvent";

export const PublicRoutes: {[key:string]: IRoute} = {
    login: {
        path: '/login',
        component: lazy(() => (import('Component/Page/Public/SignIn/SignIn'))),
        pageKey: PageEvents.LOGIN_PAGE,
    },
}
