import { BaseModel } from 'Domain/Model/BaseModel/BaseModel';

export interface IImage {
    id: string,
    createdAt: number,
    foreignKey: string,
    foreignTable: string,
    height: number,

    mimeType: string,
    origin: string,
    refKey: string,
    refName: string,
    originPath: string,
    cropPath: string,
    size: number,
    sorting: number,
    width: number,
    url: string,
    key: string,
}

export class Image extends BaseModel {

    public id: string;
    public createdAt: number;
    public foreignKey: string;
    public foreignTable: string;
    public height: number;
    public mimeType: string;
    public origin: string;
    public refKey: string;
    public refName: string;
    public originPath: string;
    public cropPath: string;
    public size: number;
    public sorting: number;
    public width: number;
    public url: string;
    public key: string;


    constructor(data: IImage) {
        super();

        this.id           = data.id;
        this.createdAt    = data.createdAt;
        this.foreignKey   = data.foreignKey;
        this.foreignTable = data.foreignTable;
        this.height       = data.height;
        this.mimeType     = data.mimeType;
        this.origin       = data.origin;
        this.refKey       = data.refKey;
        this.refName      = data.refName;
        this.size         = data.size;
        this.sorting      = data.sorting;
        this.width        = data.width;
        this.originPath   = data.originPath;
        this.cropPath     = data.cropPath;

        this.url          = data.cropPath;
        this.key          = data.id;

    }

}