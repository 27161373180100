import { IFilterSettings } from "Domain/Repository/BaseRepository/BaseRepository";

export const SEARCH_SPOT_LISTS_REQUEST = 'SEARCH_SPOT_LISTS_REQUEST';

export const searchSpotListsRequest = ({
     filter = [],
     sorting = { field: 'id', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }: IFilterSettings) => ({
    type: SEARCH_SPOT_LISTS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
    }
});
