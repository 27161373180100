import { SEARCH_SPOTS_REQUEST, searchSpotsSuccess, searchSpotsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const searchSpotsEpic: TEpic = (action$, store$, { SpotRepository }) => {
    return action$.pipe(
        ofType( SEARCH_SPOTS_REQUEST ),
        mergeMap(action => from(
            SpotRepository.findAll(action.payload)
                .then(({ spots, meta }) => searchSpotsSuccess({ spots, response: meta, request: action}))
                .catch((error: Error) => searchSpotsFailure(error)),
        )),
    );
}
