export const UPDATE_SERVER_SETTINGS_SUCCESS = 'UPDATE_SERVER_SETTINGS_SUCCESS';

interface IGetSettingsSuccess {
    options: Record<string, unknown>,
}

export const updateServerSettingsSuccess = ( payload: IGetSettingsSuccess ) => ({
    type: UPDATE_SERVER_SETTINGS_SUCCESS,
    payload,
});
