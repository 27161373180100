import React, { Component } from 'react';
import {connect, MapDispatchToPropsNonObject, MapStateToProps} from 'react-redux';
import { Layout } from 'antd';
import { IReduxStore } from 'Redux/Store/IReduxStore';
import Footer from "Component/Application/Footer/Footer";
import Sideboard from 'Component/Application/Sideboard/Sideboard';
import InternalHeader from "Component/Application/Header/InternalHeader";
import Content from "Component/Application/Content/Content";
import { WINDOW_TYPES } from 'AppConfig/Config/WindowTypes';
import {getActiveMenuTab, getWindowMode, isApplicationLoading, isGlobalStateEqual, isLoggedIn} from "Redux/Query";
import { changeWindowMode } from 'Redux/Action';
import {bindActionCreators} from "redux";
import { PublicRoutes } from "AppConfig/Routes";
import Style from "./PublicApp/PublicApp.style";

interface IStateProps {
    isLoggedIn?: boolean;
    isLoading: boolean;
    windowMode: string,
    activeTab: string,
}

interface IDispatchProps {
    changeWindowMode: (windowType: string) => void,
}

interface IProps {

}

type TProps = IStateProps & IDispatchProps & IProps & { children: React.ReactElement };

class App extends Component<TProps, {}> {


    handleWindowMode = (windowSize: any) => {
        const currentWindowMode = this.props.windowMode;
        const { windowWidth: width } = windowSize;

        let newWindowMode: string = WINDOW_TYPES.PHONE;

        if(width > 1220){
            newWindowMode = WINDOW_TYPES.DESKTOP;
        }
        else if(width > 720) {
            newWindowMode = WINDOW_TYPES.TABLET;
        }

        if(currentWindowMode !== newWindowMode) {
            this.props.changeWindowMode(newWindowMode);
        }
    };


    renderApplication() {
        return (
            <Layout>
                <Sideboard />
                <Layout className="layoutWrapper" style={{ minHeight: '100vh' }}>
                    <InternalHeader />
                    <Content>
                        { this.props.children }
                    </Content>
                    <Footer />
                </Layout>
            </Layout>
        );
    }

    render () {
        if(PublicRoutes.hasOwnProperty(this.props.activeTab)) {

            return (
                <Style { ...this.props }>
                    <Content>
                        { this.props.children }
                    </Content>
                    <Footer  />
                </Style>
            );
        }

        return (
            <Layout >
                { this.renderApplication() }
            </Layout>
        );
    }
}


const mapStateToProps: MapStateToProps<IStateProps, {}, IReduxStore> = (state, ownProps) => {
    return {
        isLoggedIn: isLoggedIn(state),
        isLoading: isApplicationLoading(state),
        windowMode: getWindowMode(state),
        activeTab: getActiveMenuTab(state),
    };
};

const mapDispatchToProps: MapDispatchToPropsNonObject<IDispatchProps, IProps> = (dispatch) => {
    return bindActionCreators(
        {
            changeWindowMode,
        },
        dispatch
    );
}

const options = {
    areStatesEqual: (nextStore: IReduxStore, prevStore: IReduxStore) => {
        return (
            isGlobalStateEqual(nextStore, prevStore)
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps,  undefined, options)(App);