export const white              = '#ffffff';
export const black              = `${process.env.REACT_APP_COLORS_BLACK}`;
export const primaryColor       = `${process.env.REACT_APP_COLORS_BLACK}`;
export const fontColor          = `${process.env.REACT_APP_COLORS_FONT_COLOR}`;
export const lightFontColor     = `${process.env.REACT_APP_COLORS_LIGHT_FONT_COLOR}`;
export const red                = `${process.env.REACT_APP_COLORS_RED}`;
export const secondaryFontColor = `${process.env.REACT_APP_COLORS_RED}`;
export const bgColor            = `${process.env.REACT_APP_COLORS_BG_COLOR}`;
export const highlightColor     = `${process.env.REACT_APP_COLORS_RED}`;
export const lightGrey          = `${process.env.REACT_APP_COLORS_LIGHT_GREY}`;
export const borderHoverColor     = `${process.env.REACT_APP_COLORS_RED}`;
export const buttonPrimaryBgColor = `${process.env.REACT_APP_BUTTON_PRIMARY_BG_COLOR}`;

export const menuTagColor       = `${process.env.REACT_APP_TAG_COLOR}`;
export const menuTagBgColor     = `${process.env.REACT_APP_TAG_BG_COLOR}`;
export const menuTagBorderColor = `${process.env.REACT_APP_TAG_BORDER_COLOR}`;

export const headerColor        = `${process.env.REACT_APP_HEADER_COLOR}`;
/*
{
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e;
}
 */