import {

    GET_SETTINGS_REQUEST,
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_SUCCESS,

    FIND_LANGUAGES_REQUEST,
    FIND_LANGUAGES_SUCCESS,
    FIND_LANGUAGES_FAILURE,

    RELOAD_SERVER_SETTINGS_REQUEST,
    RELOAD_SERVER_SETTINGS_SUCCESS,
    RELOAD_SERVER_SETTINGS_FAILURE,

    SET_ACTIVE_LANGUAGE,

    UPDATE_SERVER_SETTINGS_REQUEST,
    UPDATE_SERVER_SETTINGS_SUCCESS,
    UPDATE_SERVER_SETTINGS_FAILURE,

    APPLICATION_BOOTSTRAPPED,

} from "Redux/Action";
import { removeOpenRequest, removeFailedRequest } from 'ClientService/ReducerUtil';
import { Reducer } from "redux";
import { PersistState, TPersistState } from "Redux/Store/Persist/PersistState";

const initialState = PersistState.getInitialState();

export const persistReducer: Reducer<TPersistState> = (state = initialState, action ) => {
    switch( action.type ) {

        case APPLICATION_BOOTSTRAPPED:
            const updatedAt = Date.now();
            if(updatedAt - state.updatedAt > 3600 * 1000) {
                return {
                    ...state,
                    updatedAt,
                }
            }

            return {
                ...state,
                updatedAt: Date.now(),
            }

        case RELOAD_SERVER_SETTINGS_REQUEST:
        case FIND_LANGUAGES_REQUEST:
        case UPDATE_SERVER_SETTINGS_REQUEST:
        case GET_SETTINGS_REQUEST:
            return {
                ...state,
                openRequests: [...new Set([...state.openRequests, action.type])],
                failedRequests: removeFailedRequest(action, state),
                updatedAt: Date.now(),
            };

        case GET_SETTINGS_SUCCESS:
        case UPDATE_SERVER_SETTINGS_SUCCESS:
        case RELOAD_SERVER_SETTINGS_SUCCESS:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                updatedAt: Date.now(),
                settings: action.payload.options,
            };

        case FIND_LANGUAGES_SUCCESS:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                updatedAt: Date.now(),
                availableLanguages: action.payload.languages,
            };

        case SET_ACTIVE_LANGUAGE:
            return {
                ...state,
                updatedAt: Date.now(),
                activeLanguage: action.payload.activeLanguage,
            };

        case GET_SETTINGS_FAILURE:
        case FIND_LANGUAGES_FAILURE:
        case UPDATE_SERVER_SETTINGS_FAILURE:
        case RELOAD_SERVER_SETTINGS_FAILURE:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                failedRequests: [...new Set([...state.failedRequests, action.type])],
                updatedAt: Date.now(),
            };


        default:
            return state;
    }
};