import {
    UPDATE_SPOT_REQUEST,
    updateSpotSuccess,
    updateSpotFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const updateSpotEpic: TEpic = (action$, store$, { SpotRepository }) => {
    return action$.pipe(
        ofType(UPDATE_SPOT_REQUEST),
        mergeMap(action => from(SpotRepository.update( action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap((spot) => {
                    return from([
                        showSuccessNotification(`Die Speicherung der Spots erfolgreich.`),
                        updateSpotSuccess(spot),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateSpotFailure(error)
                    ])
                })
            )
        ),
    );
}
