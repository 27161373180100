export const removeFailedRequest = (action, state) => {
    const failedRequests = [...state.failedRequests];
    let actionType = '';
    if(action.type.match('REQUEST')) {
        actionType = action.type.replace('REQUEST', 'FAILURE');
    }
    else {
        return failedRequests;
    }

    let index = failedRequests.indexOf(actionType);
    while(index > -1){
        failedRequests.splice(index, 1);
        index = failedRequests.indexOf(actionType);
    }
    return failedRequests;
};
