import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_SPOT_LIST_REQUEST,
    FIND_SPOT_LISTS_REQUEST,
    SEARCH_SPOT_LISTS_REQUEST,
    CREATE_SPOT_LIST_REQUEST,
    UPDATE_SPOT_LIST_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isSpotListLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.spotList.openRequests, [
        FIND_SPOT_LIST_REQUEST,
        FIND_SPOT_LISTS_REQUEST,
        SEARCH_SPOT_LISTS_REQUEST,
        CREATE_SPOT_LIST_REQUEST,
        UPDATE_SPOT_LIST_REQUEST,
    ]);