import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Image }   from 'Domain/Model/Image/Image';

const config = {
    model: Image,
    baseRoute: '/image',
    singular: 'image',
    plural: 'images',
}

class ImageRepository extends BaseRepository<Image, typeof config.singular, typeof config.plural> {

}

export default new ImageRepository(config);
