import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Language }                                     from "Domain/Model/Language/Language";

export const getLanguages = (state: IReduxStore, filter: null|IFilterProps<Language> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<Language>;
    }

    return filterObjectStore(state.persist.availableLanguages, filter);
};
