export interface IUser {
    id: string,
    mail: string,
    title?: string,
    firstName?: string,
    lastName?: string,
    phone?: string,
    name?: string,
}


export default class User {

    public id:        string;
    public mail:      string;
    public title:     string;
    public firstName: string;
    public lastName:  string;
    public phone:     string;
    public name:      string;


    constructor( data: IUser  ) {

        this.id        = data.id;
        this.mail      = data.mail;
        this.title     = data.title ? data.title : '';
        this.firstName = data.firstName ? data.firstName : '';
        this.lastName  = data.lastName ? data.lastName : '';
        this.phone     = data.phone ? data.phone : '';

        this.name = '';


        if(data.title) {
            this.name += `${data.title} `;
        }
        if(data.firstName) {
            this.name += `${data.firstName} `;
        }
        if(data.lastName) {
            this.name += `${data.lastName}`;
        }
        this.name = this.name.trim();
    }

}
