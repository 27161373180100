export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

export interface INotification {
    notificationType: string,
    message: string,
    code?: string|number,
}

export const showNotification = ({ notificationType, message, code }: INotification) => ({
    type: SHOW_NOTIFICATION,
    payload: {
        notificationType,
        message,
        code,
    }
});