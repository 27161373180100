import { BaseStoreNamespace } from 'Redux/Store/BaseStore';
import { Option }             from 'Domain/Model/Setting/Option';
import { Language }           from 'Domain/Model/Language/Language';

interface IPersistState {
    settings: Record<string, Option>,
    activeLanguage: string,
    availableLanguages: Record<string, Language>
}

export type TPersistState = IPersistState & BaseStoreNamespace.TDefaultState;


export const PersistState = {

    getInitialState: (): TPersistState => ({
        updatedAt         : 0,
        activeLanguage    : 'de_DE',
        availableLanguages: {},
        settings          : {},
        openRequests      : [],
        failedRequests    : [],
    })

};