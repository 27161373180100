import React from 'react';
import ReactDOM from 'react-dom';
import { unregister as unregisterServiceWorker } from './serviceWorker';
import { Provider } from "react-redux";
import { Persistor, Store } from "Redux/Config";
import Router from "Component/Application/Router/Router";
import { PersistGate } from 'redux-persist/integration/react'
import Bootstrap from "Redux/Bootstrap";
import 'antd/dist/antd.css';
import "Public/css/global.css";
import moment from "moment";
import "moment-timezone";

moment.tz.setDefault("Europe/Berlin");

const Application = () => (
    <Provider store={ Store }>
        <PersistGate loading={null} persistor={ Persistor }>
            { /* @ts-ignore */}
            <Router />
        </PersistGate>
    </Provider>
)

Bootstrap()
    .then(() => Application())
    .catch(error => console.error(error));

ReactDOM.render(<Application />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker();