import { combineEpics } from 'redux-observable';

import { createSpotEpic }    from './CreateSpot/CreateSpotEpic';
import { deleteSpotEpic } from './DeleteSpot/DeleteSpotEpic';
import { findSpotsEpic }  from './FindSpots/FindSpotsEpic';
import { findSpotEpic }       from './FindSpot/FindSpotEpic';
import { searchSpotsEpic } from './SearchSpots/SearchSpotsEpic';
import { updateSpotEpic }  from './UpdateSpot/UpdateSpotEpic';


export default combineEpics(
    createSpotEpic,
    deleteSpotEpic,
    findSpotsEpic,
    findSpotEpic,
    searchSpotsEpic,
    updateSpotEpic,
);
