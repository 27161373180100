import {
    CREATE_SPOT_LIST_REQUEST,
    CREATE_SPOT_LIST_SUCCESS,
    CREATE_SPOT_LIST_FAILURE,

    DELETE_SPOT_LIST_REQUEST,
    DELETE_SPOT_LIST_SUCCESS,
    DELETE_SPOT_LIST_FAILURE,

    UPDATE_SPOT_LIST_REQUEST,
    UPDATE_SPOT_LIST_SUCCESS,
    UPDATE_SPOT_LIST_FAILURE,

    FIND_SPOT_LIST_REQUEST,
    FIND_SPOT_LIST_SUCCESS,
    FIND_SPOT_LIST_FAILURE,

    FIND_SPOT_LISTS_REQUEST,
    FIND_SPOT_LISTS_SUCCESS,
    FIND_SPOT_LISTS_FAILURE,

    SEARCH_SPOT_LISTS_REQUEST,
    SEARCH_SPOT_LISTS_SUCCESS,
    SEARCH_SPOT_LISTS_FAILURE,


    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer }                       from "redux";
import { SpotListState, TSpotListState } from "Redux/Store/SpotList/SpotListState";

const initialState = SpotListState.getInitialState();

export const spotListReducer: Reducer<TSpotListState> = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case CREATE_SPOT_LIST_REQUEST:
    case DELETE_SPOT_LIST_REQUEST:
    case UPDATE_SPOT_LIST_REQUEST:
    case FIND_SPOT_LIST_REQUEST:
    case FIND_SPOT_LISTS_REQUEST:
    case SEARCH_SPOT_LISTS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_SPOT_LIST_SUCCESS:
    case UPDATE_SPOT_LIST_SUCCESS:
    case FIND_SPOT_LIST_SUCCESS:
        const entry = getSingleObject(action.payload);

        return {
            ...state,
            lastInteractiveId: entry.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            spotList: {
                ...state.spotList,
                entries: addSingleObjectToReducer(state.spotList.entries, entry)
            },
        };

    case FIND_SPOT_LISTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            spotList: {
                entries: action.payload.spotLists,
                maxCount: (action.meta) ? action.meta.response.count : state.spotList.maxCount,
            },
            updatedAt: Date.now(),
        };

    case SEARCH_SPOT_LISTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            searchEntries: {
                ...state.searchEntries,
                ...action.payload.spotLists,
            },
            updatedAt: Date.now(),
        };

    case DELETE_SPOT_LIST_SUCCESS: {
        const entries = { ...state.spotList.entries };
        delete entries[action.payload.id];
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            spotList: {
                ...state.spotList,
                entries: entries,
            },
        };
    }

    case CREATE_SPOT_LIST_FAILURE:
    case DELETE_SPOT_LIST_FAILURE:
    case UPDATE_SPOT_LIST_FAILURE:
    case FIND_SPOT_LIST_FAILURE:
    case FIND_SPOT_LISTS_FAILURE:
    case SEARCH_SPOT_LISTS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };


    default:
        return state;
    }
};
