import { SHOW_INFO_NOTIFICATION, showNotification } from "Redux/Action";
import { Epic, ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";

export const showInfoNotificationEpic: Epic = ( action$ ) => {
    return action$.pipe(
        ofType( SHOW_INFO_NOTIFICATION ),
        mergeMap((action) => of(showNotification({ notificationType: 'info', message: action.payload.message })))
    )
}