import { SHOW_ERROR_NOTIFICATION, errorActionConstants, showNotification, logoutUser } from "Redux/Action";
import { Epic, ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import {EMPTY, from, of} from "rxjs";
import { defaultErrorMessages } from 'ClientService';

export const showErrorNotificationEpic: Epic = ( action$ ) => {
    return action$.pipe(
        ofType( SHOW_ERROR_NOTIFICATION, ...errorActionConstants ),
        mergeMap((action) => {

            if(action.payload.hasOwnProperty('message')) {
                return of(showNotification({ notificationType: 'error', message: action.payload.message, code: 'CUSTOM_ERROR'  }));
            }

            let message = defaultErrorMessages(action.payload);
            if(!message && !action.payload.data?.errors && !action.payload.data?.message) {
                return EMPTY;
            }

            if(message && message.hasOwnProperty('message') && !!message.message && message.message.match(/No Authorization/i)){
                if(message?.message) {
                    return of(showNotification({ notificationType: 'error', ...message }),)
                }
                if(action.payload.error.hasOwnProperty('loginFailure')) {
                    return of(showNotification({ notificationType: 'error', message: 'Nutzername oder Passwort stimmen nicht überein.', code: message.code }),)
                }
                return from([
                    showNotification({ notificationType: 'error', message: 'Authorisierungsfehler! Bitte loggen Sie sich neu ein.', code: message.code }),
                    logoutUser()
                ]);
            }

            if(!!action.payload.data?.errors && action.payload.data.errors[0].errorMessage) {
                return of(showNotification({ notificationType: 'error', message: action.payload.data.errors[0].errorMessage, code: 'CUSTOM_ERROR'  }));
            }
            if(!!action.payload.data?.message && !!action.payload.data?.route) {
                return of(showNotification({ notificationType: 'error', message: `${action.payload.data.message} (${action.payload.data.route})`, code: 'ROUTE_ERROR'  }));
            }

            switch(message?.code) {

                default: break;
            }


            return of(showNotification({ notificationType: 'error', message: message?.message, code: message?.code }))
        })
    )
}