import { create } from 'apisauce';

const requestHeader = {
    'Content-Type': 'application/json; charset=utf-8',
};

const api = create({
    baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:5500',
    headers: requestHeader,
});

export const requestApi = {
    api,
    get: api.get,
    post: api.post,
    patch: api.patch,
    head: api.head,
    delete: api.delete,
    put: api.put,
    link: api.link,
    unlink: api.unlink,
    any: api.any,

    lastAccessToken: null,
    lastRefreshToken: null,
    resetHeader: () => (additionalHeaderData = {}) => {
        api.setHeaders(Object.assign({}, requestHeader, additionalHeaderData));

        return requestApi;
    },
    setHeader: api.setHeader,
};
