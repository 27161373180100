import { FIND_SPOT_LISTS_REQUEST, findSpotListsSuccess, findSpotListsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findSpotListsEpic: TEpic = (action$, store$, { SpotListRepository }) => {
    return action$.pipe(
        ofType( FIND_SPOT_LISTS_REQUEST ),
        mergeMap(action => from(
            SpotListRepository.findAll(action.payload)
                .then(({ spotLists, meta }) => findSpotListsSuccess({ spotLists, response: meta, request: action}))
                .catch((error: Error) => findSpotListsFailure(error)),
        )),
    );
}
