import { Tag } from "Domain/Model/Tag/Tag";

export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS';

interface IFindTagsSuccess {
    tags: Record<string, Tag>,
    request: {},
    response: {},
}

export const searchTagsSuccess = ({ tags, request, response }: IFindTagsSuccess) => ({
    type: SEARCH_TAGS_SUCCESS,
    payload: { tags },
    meta: {
        request,
        response,
    }
});
