import { SpotList } from "Domain/Model/SpotList/SpotList";

export const SEARCH_SPOT_LISTS_SUCCESS = 'SEARCH_SPOT_LISTS_SUCCESS';

interface IFindSpotListsSuccess {
    spotLists: Record<string, SpotList>,
    request: {},
    response: {},
}

export const searchSpotListsSuccess = ({ spotLists, request, response }: IFindSpotListsSuccess) => ({
    type: SEARCH_SPOT_LISTS_SUCCESS,
    payload: { spotLists },
    meta: {
        request,
        response,
    }
});
