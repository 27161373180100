import BaseRepository from '../BaseRepository/BaseRepository';
import User from 'Domain/Model/User/User';
import { requestApi } from 'ClientService';

const config = {
    model: User,
    baseRoute: '/admin/user',
    singular: 'user',
    plural: 'users',
}

class UserRepository extends BaseRepository<User, typeof config.singular, typeof config.plural> {

    checkAuthorization() {
        return this.makeRequest({
            path: `/admin/user/check-authentication`,
            type: 'GET',
            callback: this.parseUser
        });
    }

    login({ mail, password }: { mail: string, password: string }) {
        return this.makeRequest({
            path: `/admin/user/authenticate`,
            type: 'POST',
            data: {
                username: mail,
                password,
                client_id: 'service_user',
                grant_type: 'password',
            },
            callback: this.parseUser
        });
    }

    logout() {
        return this.makeRequest({
            path: `/admin/user/logout`,
            type: 'GET',
            callback: this.resetHeader
        });
    }

    find() {
        return this.makeRequest({
            path: `/admin/user`,
            type: 'GET',
            callback: this.parseUser
        });
    }

    refreshAccessToken(refreshToken: string) {
        return this.makeRequest({
            path: `/user/refresh-access-token`,
            type: 'POST',
            data: {
                refresh_token: refreshToken,
                client_id: 'livetain_user',
                grant_type: 'refresh_token',
            },
            callback: this.parseUser
        });
    }

    setAuthorisationHeader(accessToken: string) {
        requestApi.setHeader('Authorization', `Bearer ${accessToken}`);
    }

    resetHeader = () => {
        requestApi.resetHeader();

        return null;
    };

    validateToken = (response: any) => {
        return !!response.success;
    };

    parseUser = ( response: any ) => {
        let user = new User(response.user || {});
        let authentication = null;

        if(response.hasOwnProperty('accessToken')){
            this.setAuthorisationHeader(response.accessToken);
            authentication = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                accessTokenExpiresAt: response.accessTokenExpiresAt,
                refreshTokenExpiresAt: response.refreshTokenExpiresAt,
            };

            requestApi.lastAccessToken  = response.accessToken;
            requestApi.lastRefreshToken = response.refreshToken;
        }
        if(response.hasOwnProperty('user') ){
            user = new User({ ...response.user } || {});

        }

        return { user, authentication };
    };

}

export default new UserRepository(config);