import React, { Component } from 'react';
import Clock from 'Component/Component/Clock/Clock';
import LayoutHeader from './Header.style';

export default class InternalHeader extends Component {
    render() {

        return (
            <LayoutHeader>
                <Clock />
            </LayoutHeader>
        );
    }
}
