import styled from "styled-components";
import {bgColor, buttonPrimaryBgColor} from "Public/css/colors";

export default styled.div `
    position: relative;
    min-height: 100vh;
    background-color: ${bgColor};
    
    
    .textPrimaryColor {
        color: ${ buttonPrimaryBgColor }  !important;
    }
    
    a {
        color: ${ buttonPrimaryBgColor }  !important;
    }
    
`;

