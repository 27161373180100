import * as Global       from "./Global";
import * as Notification from "./Notification";
import * as User         from "./User";
import * as SpotList     from "./SpotList";
import * as Spot         from "./Spot";
import * as Image        from "./Image";
import * as System       from "./System";
import * as Tag          from "./Tag";
import * as Language     from "./Language";

export * from "./Global";
export * from "./Notification";
export * from "./User";
export * from "./SpotList";
export * from "./Spot";
export * from "./Image";
export * from "./System";
export * from "./Tag";
export * from "./Language";


const bundledActions = {
    ...Global,
    ...Notification,
    ...User,
    ...SpotList,
    ...Spot,
    ...Image,
    ...System,
    ...Tag,
    ...Language,
};

const errorRegEx = RegExp('FAILURE');
export const errorActionConstants = Object.keys(bundledActions)
    .filter(actionConstant => errorRegEx.test(actionConstant));
